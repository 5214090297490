//$grid-breakpoints: (
//    xs: 0,
//    sm: 576px,
//    md: 768px,
//    lg: 992px,
//    xl: 1200px
//);

$aql-green: #65AC1E;
$aql-lightgreen: lighten($aql-green, 40%);
$aql-lightergreen: lighten($aql-green, 52.5%);
$aql-lightergreen-trans: transparentize($aql-lightgreen, .5);
$aql-orange: #f39500;
$aql-lightorange: lighten($aql-orange, 40%);
$aql-lightorange-trans: transparentize($aql-lightorange, .5);
$aql-lighterorange: lighten($aql-orange, 47%);
$aql-background: #F0F0F0;
$aql-darker-background: darken($aql-background, 20%);
$aql-background-hover: #f7f8fa;
$aql-background-tooltip: #34495e;
$aql-panel-border: #e4e5e7;

$primary: $aql-green;
$min-contrast-ratio: 2.8;

$aql-font-color: #6a6c6f;
$aql-light-font-color: lighten($aql-font-color, 30%);

$aql-main-header-height: 57px;
$aql-page-header-height: 53px;

